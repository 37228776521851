export const DASSPER_TOOL_URL = "https://tools.dassper.com/"
export const AES_256_ENCODER_DECODER_SECRET_KEY = 'f1g6sTKtOE39QYJG'



// // calls are recorded and converted to text
// // call transcript data

// // 1 Lakh call in our DB
// // each call can have length 2000 tokens/words
// // competitor analysis using LLM (chat gpt) (1L 28Th tokens in single API call)


// 1L (chatgpt)  ->  ~~ 2000 calls to process this data
// 2000Lakh token/words

// 500Lakhs token/words


// 300Lakhs token/words

// 30Laks tokens

// //  each transcript does not have data which we need to analyze, we can remove that
// //  summarize the data for each call transcript
// //  send group of data to analyze, and based on that we can generate summary (which includes key points)
//     // each group data in analyzed separately and then concatenated together and send back to gpt for further analysis



// // 25 out of 100 customers compare with other companies