import styles from "../css/base64EncodeDecode.module.scss"
import {useEffect, useState} from "react";
import { DASSPER_TOOL_URL } from "../utils/constants";

const ArrowSVGIcon = ({setIsEncoding, setUserInput, resultantText}) => (
    <svg
      onClick={()=>{
        setUserInput(resultantText);
        setIsEncoding((prev)=> !prev);
      }}
      height="25px"
      width="25px"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132.566 132.566"
      fill="#000000"
      style={{ transform: 'rotate(-45deg)' }} // Use style for transform
    >
      <g>
        <path
        className={styles.colorAnimation}
          style={{ fill: 'green' }}
          d="M84.124,132.566l-84.109-0.012L0.003,48.439l24.578,24.589l48.445-48.445L48.436,0l84.115,0.012
          l0.012,84.115l-24.572-24.578l-48.457,48.439L84.124,132.566z M5.982,126.587l63.732,0.012l-18.617-18.605l56.894-56.876 
          l18.605,18.605l-0.012-63.744L62.846,5.967l18.617,18.617L24.581,81.466L5.97,62.855L5.982,126.587z"
        />
      </g>
    </svg>
  );

  
  
  const Base64EncodeDecode = ()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // alert(urlParams.get('is_encoding'))
    const queryParamsEncoding = urlParams.get('is_encoding');
    const queryParamsData = decodeURIComponent(urlParams.get("data", ""));
    console.log(typeof queryParamsData)
    const [userInput, setUserInput] = useState(queryParamsData!="null" ? queryParamsData : "");
    const [isEncoding, setIsEncoding] = useState(queryParamsEncoding ? queryParamsEncoding=='true' : true);
    const [resultantText, setResultantText] = useState(true);
    
    const handleEncodeDecode = (text)=>{
      setUserInput(text);
      if (isEncoding) {
        const binaryString = new TextEncoder().encode(text); // Convert to Uint8Array
        const base64String = btoa(String.fromCharCode(...binaryString)); // Encode to Base64
        setResultantText(base64String); // Encode the text to Base64
      }
      else{
        const binaryString = atob(text); // Decode Base64 to binary string
        const uint8Array = new Uint8Array(binaryString.length);
        
        // Populate Uint8Array with the binary string's character codes
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        
        // Decode the Uint8Array to a UTF-8 string
        const decodedText = new TextDecoder('utf-8').decode(uint8Array);
        setResultantText(decodedText); // Decode the text from Base64
      }
    }
    
    const CopySVGIcon = () => {
      const [isCopied, setIsCopied] = useState(false);

      useEffect(()=>{
        if (isCopied) {
          const url = DASSPER_TOOL_URL+"?active_tool=0&data="+encodeURIComponent(resultantText)+"&is_encoding="+!isEncoding
          navigator.clipboard.writeText(url);
          setTimeout(()=>setIsCopied(false), 1000)
        }
      },[isCopied])

      return <span className={styles.copy_button} onClick={()=>setIsCopied(true)}><svg
        fill="#fff"
        height="20px"
        width="20px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 352.804 352.804"
        xmlSpace="preserve"
        stroke="#a3a3a3"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <g>
            <path d="M318.54,57.282h-47.652V15c0-8.284-6.716-15-15-15H34.264c-8.284,0-15,6.716-15,15v265.522c0,8.284,6.716,15,15,15h47.651v42.281c0,8.284,6.716,15,15,15H318.54c8.284,0,15-6.716,15-15V72.282C333.54,63.998,326.824,57.282,318.54,57.282z M49.264,265.522V30h191.623v27.282H96.916c-8.284,0-15,6.716-15,15v193.24H49.264z M303.54,322.804H111.916V87.282H303.54V322.804z"></path>
          </g>
        </g>
      </svg> {isCopied ? "Copied!" : "Copy"}
      </span>
    }
    const handleInputChange = (e) => {
        const text = e.target.value;
        handleEncodeDecode(text);
      };

    useEffect(()=>{
        handleEncodeDecode(userInput)
    }, [isEncoding]);

    return <div className={styles.encode_decode_main}>
        <h1>Base 64 Encode/Decode</h1>
        <div>
            <div className={styles.encoding_buttons}>
                <span className={isEncoding ? styles.active : null} onClick={() => setIsEncoding(!isEncoding)}>Encode</span>
                <span className={!isEncoding ? styles.active : null} onClick={() => setIsEncoding(!isEncoding)}>Decode</span>
            </div>
            <div className={styles.user_input}>
                <CopySVGIcon />
                <textarea value={userInput} onChange={handleInputChange} placeholder="Type (or paste) here ...">
                  
                </textarea>
            </div>
            <ArrowSVGIcon setIsEncoding={setIsEncoding} resultantText={resultantText} setUserInput={setUserInput} />
            
            <div className={styles.result}>
                <textarea value={resultantText} disabled></textarea>
            </div>
        </div>
    </div>
}

export default Base64EncodeDecode;