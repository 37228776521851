import logo from './logo.svg';
import './App.css';
import {useState} from "react";
import Header from "./components/header.js";
import Home from "./pages/home.js";
import ToolsContext from './utils/toolsContext.js';

function App() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryParamsActive = parseInt(urlParams.get('active_tool', '0'))

  const [activeTool, setActiveTool] = useState(queryParamsActive ? queryParamsActive : 0);

  return (
    <ToolsContext.Provider value={{activeTool: activeTool, setActiveTool: setActiveTool}}>
      <div className="App">
        <Header/>
        <hr/>
        <Home/>
        <hr/>
        <p>Crafted with ❤️ in India</p>
      </div>
    </ToolsContext.Provider>
  );
}

export default App;
