import {useState} from "react";
import styles from "../css/jsonFormatter.module.scss";
import ReactJson from 'react-json-view';

const JSONFormatter = ()=>{
    const [inputJSON, setInputJSON] = useState("{}")
    const [outputJSON, setOutputJSON] = useState("{}")
    const [tabSize, setTabSize] = useState(2)

    const formatJSON = ()=>{
        const formattedJson = inputJSON
        .replace(/(["']?)(\w+)(["']?)(:)/g, '"$2"$4') // Replace single quotes around keys/values with double quotes
        .replace(/:\s*([^,}]+)/g, (match, value) => {
            let new_value = value.trim();
            // console.log(new_value[0])
            if (new_value[0]!=="'" && new_value[0]!=='"')
                {
                console.log("inside")
                new_value = new_value.replace("None", "null")
                .replace("True", "true")
                .replace("False", "false")
                return `: ${new_value}`
            }
            new_value = new_value.slice(1, -1)
            new_value = new_value.replaceAll('"', "'")
            return `: "${new_value}"`
        })

        console.log(formattedJson)
        console.log(tabSize)
        const formattedData = JSON.stringify(JSON.parse(formattedJson), null, tabSize);
        setOutputJSON(formattedData);
    }

    const minigyJSON = ()=>{
        const formattedJson = inputJSON.replace(/(["']?)(\w+)(["']?)(:)/g, '"$2"$4') // Replace single quotes around keys/values with double quotes
        .replace(/:\s*([^,}]+)/g, (match, value) => {
            let new_value = value.trim();
            // console.log(new_value[0])
            if (new_value[0]!=="'" && new_value[0]!=='"')
                {
                console.log("inside")
                new_value = new_value.replace("None", "null")
                .replace("True", "true")
                .replace("False", "false")
                return `: ${new_value}`
            }
            new_value = new_value.slice(1, -1)
            new_value = new_value.replaceAll('"', "'")
            return `: "${new_value}"`
        })

        const formattedData = JSON.stringify(JSON.parse(formattedJson));
        setOutputJSON(formattedData);
    }

    const output_style = {
        "color": "#aaaaaa",
        "background": "#444444",
        "box-sizing": "border-box",
        "margin-top": "5vh",
        "width": "43%",
        "height": "70vh",
        "overflow": "scroll",
        "border-radius": "10px",
        "outline": "none",
        // resize: none;
        
        "font-family": "monospace",
        "transition": "background 0.25s, color 0.25s",
        "text-align": "left"
        // &:hover, &:focus{
        //     background: #4a4a4a;
        // }
        // &:focus{
        //     color: #eeeeee;
        // }
    }

    return <div className={styles.json_formatter_main}>
        <div className={styles.container}>
            <textarea value={inputJSON} onChange={(e)=>setInputJSON(e.target.value)} className={`${styles.text_area}`} placeholder="Enter your JSON here...">
                
            </textarea>
            <div className={styles.controls}>
                <button onClick={formatJSON}>Format</button>
                {/* <button onClick={minigyJSON}>Minify</button> */}
                <select className={styles.tab_size} onChange={(e)=>setTabSize(parseInt(e.target.value))}>
                    <option value={2}>2 Tabs</option>
                    <option value={3}>3 Tabs</option>
                    <option value={4}>4 Tabs</option>
                </select>
            </div>
            <ReactJson style={output_style} src={JSON.parse(outputJSON)} name={false} theme="monokai" indentWidth={tabSize} collapsed={false} />
            {/* <textarea value={outputJSON} readOnly className={`${styles.text_area}`} placeholder="Your JSON will appear here..."></textarea> */}

        </div>
</div>
}

export default JSONFormatter;