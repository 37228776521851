import React, { useState } from 'react';
import styles from "../css/bkFans.module.scss"
// Generate random position
const randomPosition = () => {
    const x = Math.random() * (window.innerWidth - 100); // Subtract box width
    const y = Math.random() * (window.innerHeight - 100); // Subtract box height
    return { x, y };
};

const { x, y } = randomPosition();

const BKFans = ()=>{
    const [showSecondBox, setShowSecondBox] = useState(false);

    const handleMouseEnter = () => {
        setShowSecondBox(true);
    };

    const handleMouseLeave = () => {
        setShowSecondBox(false);
    };


    const boxStyle = {
        position: 'absolute',
        top: `${y}px`,
        left: `${x}px`,
        width: '200px',
        height: '100px',
        backgroundColor: 'lightblue',
        // border: '2px solid blue',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        fontWeight: 'bold',
    };

    return (<div>
        Only BK Fans can find it !!
        <br/>
        <br/>
        <div style={boxStyle} className={styles.show_box} onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
            Batauuuu Kadhhhiiii
        </div>
        {showSecondBox ?
        <div>
            🎉✨ Congratulations! ✨🎉 
            <br/>
            🌟 You won a 🌟 <b>Batauuuu Kadhhhiiii</b> 🎶✨ **music album** 🎶 for free! 🎶
        <div className={styles.viewport}>
        <div className={styles.wrapper}>
          <div className={`${styles.disc} ${styles.disc_gradient}`}>
            
             <div className={styles.disc_hole}>Batauuuu Kadhiiii</div>
          </div>
        </div>
      </div></div>: null}
    </div>
    );
}

export default BKFans;