import { useContext, useState } from "react";
import ToolsContext from "../utils/toolsContext";
import Base64EncodeDecode from "../components/base64EncodeDecode";
import JSONFormatter from "../components/jsonFormatter";
import BKFans from "../components/bkFans";
import AES256EncoderDecoder from "../components/aes256EncoderDecoder";

const Home = ()=>{
    const {activeTool, setActiveTool} = useContext(ToolsContext)
    const tools = {
        0: {
            name: "Base 64 Encode/Decode",
            component: <Base64EncodeDecode/>
        },
        1: {
            name: "JSON Diff",
            component: <h1>... Work In Progress..</h1>
        },
        2: {
            name: "JSON Formatter",
            component: <JSONFormatter/>
        },
        3: {
            name: "Find and Replace",
            component: <h1>... Work In Progress..</h1>
        },
        4: {
            name: "Chat Buddy",
            component: <h1>... Work In Progress..</h1>
        },
        5: {
            name: "BK Fans",
            component: <BKFans/>
        },
        6: {
            name: "AES 256 Encryptor",
            component: <AES256EncoderDecoder/>
        },
    };

    return (
        <div>
            {tools[activeTool].component}
            
        </div>
    )
}

export default Home;