import logo from '../logo.svg';
import styles from '../css/header.module.scss'
import { useContext } from 'react';
import ToolsContext from "../utils/toolsContext.js";

const Header = () => {
    const {activeTool, setActiveTool} = useContext(ToolsContext)
    const dassperLogo = "https://dassper.com/wp-content/uploads/2024/06/Dassper-Logo-157x157.png.webp"
    const toolsMenu = {
        0: {
            name: "Base 64 Encode/Decode",
        },
        1: {
            name: "JSON Diff",
        },
        2: {
            name: "JSON Formatter",
        },
        3: {
            name: "Find and Replace",
        },
        4: {
            name: "Chat Buddy",
        },
        5: {
            name: "BK Fans",
        },
        6: {
            name: "AES 256 Encode/Decode",
        }
    };

    console.log(activeTool);
    return (
        <div className={styles.header}>
            <div><img src={dassperLogo} className="logo" alt="logo" height="100vh"/>
            </div>
            <div className={styles.header_menu}>
                {Object.keys(toolsMenu).map((key, index) => {
                    return <span key={index} className={key==activeTool ? styles.active_tool : null} onClick={() => setActiveTool(index)}>{toolsMenu[key].name}</span>
                })}
            </div>
        </div>
    );
}

export default Header;